<template>
  <el-dialog :title="ModalTitle" v-model="shouldShow">
    <el-row type="flex" justify="start">
      <span>{{ subtitle }}</span>
    </el-row>

    <base-input label="Valor pago:" v-model="amount_" type="money">
    </base-input>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button
          type="text"
          :isLoading="isLoadingSave"
          class="button"
          @click="save"
          >Salvar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import BaseInput from "../../components/BaseInput.vue";
export default {
  props: ["showModal", "title", "subtitle", "payment"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      amount_: null,
      isLoadingSave: false,
    };
  },
  watch: {
    payment(v) {
      this.amount_ = v?.amount;
    },
  },
  computed: {
    ModalTitle() {
      return this.title;
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    save() {
      this.$emit("update-payment", { ...this.payment, amount: this.amount_ });
      this.$emit("close-modal");
    },
  },
  name: "FinalizeOrderPaymentModal",
};
</script>
