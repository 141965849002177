<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <el-col :md="8" :xs="24">
          <el-row type="flex" justify="start">
            <h4>Baixar Pagamentos</h4>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <base-input
      v-model="searchOrder"
      v-on:keyup="fetchOrder"
      label="Numero do Pedido:"
      style="margin-bottom: 16px"
    >
    </base-input>
    <el-empty
      v-if="!order"
      description="Nenhum pedido para mostrar aqui"
    ></el-empty>
    <el-row type="flex" v-else :gutter="8">
      <el-col :md="12" :xs="24">
        <el-table
          :data="OrderPayments"
          :row-class-name="
            ({ row: payment }) => (payment.paid_at ? 'success' : '')
          "
          style="width: 100%; z-index: 0"
          :cell-style="() => 'text-align:center;'"
        >
          <el-table-column
            prop="payment_method_formatted"
            label="faturamento"
            min-width="250"
          >
          </el-table-column>
          <el-table-column
            label="valor"
            :formatter="(r) => currencyFormatter.format(Number(r.amount))"
          >
          </el-table-column>
          <el-table-column label="ações" :min-width="130">
            <template #header>
              <el-button
                onlyIcon
                type="primary"
                title="Conferir todos os pagamentos do pedido"
                plain
                :disabled="!OnlyUncheckedPayments?.length"
                style="margin-left: auto; margin-right: 4px"
                @click="checkAllPayments()"
              >
                <i class="el-icon-check"></i>
                conferir tudo
              </el-button>
            </template>
            <template #default="{ row: payment }">
              <el-row type="flex" justify="end">
                <el-button-group>
                  <el-button
                    onlyIcon
                    :type="payment.is_checked ? 'success' : 'primary'"
                    :disabled="payment.is_checked"
                    title="Conferir este pagamento do pedido"
                    plain
                    @click="openFinalizePaymentModal(payment)"
                  >
                    <i class="el-icon-check"></i>
                  </el-button>
                  <el-popconfirm
                    @confirm="deletePayment(payment)"
                    title="Tem certeza?"
                    ><template #reference>
                      <el-button onlyIcon type="danger">
                        <i class="el-icon-delete"></i>
                      </el-button> </template
                  ></el-popconfirm>
                </el-button-group>
              </el-row>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :md="12" :xs="24">
        <el-descriptions
          border
          :column="2"
          title="Resumo de Pagamentos"
          direction="vertical"
        >
          <template #extra>
            <el-button
              onlyIcon
              type="primary"
              title="Conferir todos os pagamentos do pedido"
              plain
              @click="openOrderPaymentModal()"
            >
              adicionar pagamento
            </el-button>
          </template>
          <el-descriptions-item>
            <template #label> Total do Pedido </template>
            {{ currencyFormatter.format(Number(order.total_amount)) }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label> Total de Pagamentos Cadastrados </template>
            {{ currencyFormatter.format(sumTotalPayments()) }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label> Total de Pagamentos Conferidos </template>
            {{ currencyFormatter.format(sumTotalPaidPayments()) }}
          </el-descriptions-item>
          <el-descriptions-item class-name="danger">
            <template #label> Saldo Restante </template>
            {{
              currencyFormatter.format(
                sumTotalPayments() - sumTotalPaidPayments()
              )
            }}
          </el-descriptions-item>
        </el-descriptions>
      </el-col>
    </el-row>
    <order-payment-modal
      :showModal="showOrderPaymentModal"
      @should-update="fetchOrder()"
      @close-modal="showOrderPaymentModal = false"
      :order="order"
    ></order-payment-modal>
    <finalize-order-payment-modal
      :showModal="showFinalizeOrderPaymentModal"
      @update-payment="checkPayment"
      :payment="orderPayment"
      @close-modal="showFinalizeOrderPaymentModal = false"
      title="Baixar pagamento"
      subtitle="Informe o montante que foi pago"
    ></finalize-order-payment-modal>
  </el-card>
</template>

<script>
import BaseInput from "../components/BaseInput.vue";
import OrderService from "../services/orders";
import { currencyFormatter } from "../utils/formatters";
import { notifySuccess } from "../utils/notifiers";
import OrderPaymentModal from "./modals/OrderPaymentModal.vue";
import FinalizeOrderPaymentModal from "./modals/FinalizeOrderPaymentModal";
export default {
  name: "FinalizeOrder",
  components: { BaseInput, OrderPaymentModal, FinalizeOrderPaymentModal },
  data: () => ({
    isLoading: false,
    searchOrder: null,
    showOrderPaymentModal: false,
    showFinalizeOrderPaymentModal: false,
    order: null,
    orderPayment: null,
    currencyFormatter,
  }),
  computed: {
    OrderPayments() {
      return this.order?.payments;
    },
    OnlyUncheckedPayments() {
      return this.OrderPayments.filter((p) => !p.is_checked);
    },
  },
  methods: {
    openFinalizePaymentModal(payment) {
      this.showFinalizeOrderPaymentModal = true;
      this.orderPayment = payment;
    },
    openOrderPaymentModal() {
      this.showOrderPaymentModal = true;
    },
    async fetchOrder() {
      this.isLoading = true;

      const {
        orders: [order],
      } = await OrderService().index({
        code: this.searchOrder,
      });

      if (order) this.order = order;
      else this.order = null;

      this.isLoading = false;
    },
    async createOrderPayment(_) {
      const { payment } = await OrderService(this.order.uid)
        .Payments()
        .create(_);

      if (payment) this.fetchOrder();
    },
    isPartialAmountPaid() {},
    async checkPayment({ uid, paid_at, amount }) {
      if (Number(amount) < Number(this.orderPayment.amount))
        this.createOrderPayment({
          expires_at: this.orderPayment.expires_at,
          amount: Number(this.orderPayment.amount) - Number(amount),
          payment_method: this.orderPayment.payment_method,
          obs: `Criado após baixa parcial no valor de ${this.currencyFormatter.format(
            Number(amount)
          )}`,
        });
      this.updateOrderPayment({
        uid,
        checked_by: this.$store.state.user.uid,
        amount,
        paid_at: paid_at || new Date(),
      });
    },
    async deletePayment(_) {
      const { payment } = await OrderService(this.order.uid)
        .Payments(_?.uid)
        .delete();

      if (payment) this.fetchOrder();
    },
    async checkAllPayments() {
      const updates = this.OnlyUncheckedPayments.map(({ uid, paid_at }) =>
        this.checkPayment({
          uid,
          checked_by: this.$store.state.user.uid,
          paid_at: paid_at || new Date(),
        })
      );

      await Promise.all(updates);

      notifySuccess("Pagamentos atualizados com sucesso");

      this.fetchOrder();
    },
    async updateOrderPayment({ paid_at, uid, amount, checked_by }) {
      const { payment } = await OrderService(this.order.uid)
        .Payments(uid)
        .update({ paid_at, checked_by, amount });

      if (payment) {
        this.fetchOrder();
        notifySuccess("Pagamento atualizado com sucesso");
      }
    },
    sumTotalPayments() {
      return this.OrderPayments?.reduce((t, p) => t + Number(p.amount), 0);
    },
    sumTotalPaidPayments() {
      return this.OrderPayments?.reduce(
        (t, p) => (p.is_checked ? t + Number(p.amount) : t),
        0
      );
    },
  },
};
</script>
<style>
.el-table .success {
  background-color: #dcedc8 !important;
}
</style>
